import Clipboard from 'clipboard';
import he from 'he';
import tippy from 'tippy.js';

export function copyToClipboard() {
  let selector = document.querySelectorAll('.js-copy-to-clipboard');
  const uri = window.location.href;

  for (let i = selector.length - 1; i >= 0; i--) {
    let trigger = selector[i];
    let content = he.decode(trigger.innerHTML) + ' - ' + uri;

    trigger.classList.add('o-copyable');
    trigger.setAttribute('title', 'Copied to clipboard');

    let timeoutID;

    let tip = tippy(trigger, {
      arrow: true,
      trigger: 'manual',
      hideOnClick: false,
      dynamicTitle: true,
      animation: 'shift-away',
      distance: 5,
      onShow: function() {
        timeoutID = window.setTimeout(function() {
          trigger._tippy.hide();
        }, 2000);
      },
      onHide: function() {
        window.clearTimeout(timeoutID);
      }
    });

    trigger._tippy.enable();

    let clipboardEl = new Clipboard(trigger, {
      text: function(trigger) {
        return content;
      }
    });

    clipboardEl.on('success', function() {
      trigger._tippy.show();
    });
  }
}
