import Waypoint from 'waypoints';
import imagesLoaded from 'imagesloaded';

const HALF_DIVISOR = 2;

export function dockSocialWidget () {
  const isNativeSticky = detectStickySupport();

  if (isNativeSticky) {
    return;
  }

  const railEl = document.querySelector('.c-post-social');

  if (!railEl) {
    return false;
  }

  const instanceEl = document.querySelector('.c-post-social__inner');
  const containerEl = railEl.parentNode;

  const enterWaypoint = new Waypoint({
    element: railEl,
    handler () {
      instanceEl.classList.toggle('is-fixed');
    },
    offset: '50%'
  });

  const exitWaypoint = new Waypoint({
    element: railEl,
    handler () {
      instanceEl.classList.toggle('is-docked');
    },
    offset () {
      return -railEl.clientHeight + window.innerHeight / HALF_DIVISOR + instanceEl.clientHeight;
    }
  });

  imagesLoaded(containerEl, () => {
    enterWaypoint.context.refresh();
    exitWaypoint.context.refresh();
  });
}

function detectStickySupport () {
  if (!window.CSS) {
    return false;
  }

  if (
    CSS.supports &&
    CSS.supports('(position: sticky)') ||
    CSS.supports('(position: -webkit-sticky)')
  ) {
    return true;
  }

  return false;
}
