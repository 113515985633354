export function classToggler() {
  let trigger = document.querySelectorAll('.js-toggle-class');

  for (let i = trigger.length - 1; i >= 0; i--) {
    let triggerEl = trigger[i];

    triggerEl.addEventListener('click', clickEvent, false);
  }
}

function clickEvent (e) {
  e.preventDefault();

  let triggerEl = findCorrectClickTarget(e.target, 'js-toggle-class');
  let targetEl = document.querySelector(triggerEl.dataset.targetEl);
  let classToToggle = triggerEl.dataset.class;

  if (targetEl.classList.contains(classToToggle)) {
    targetEl.classList.remove(classToToggle);
    triggerEl.classList.add('target-class-off');
    triggerEl.classList.remove('target-class-on');
  } else {
    targetEl.classList.add(classToToggle);
    triggerEl.classList.remove('target-class-off');
    triggerEl.classList.add('target-class-on');
  }
}

function findCorrectClickTarget (el, cls) {
  while (!el.classList.contains(cls)) {
    el = el.parentElement;
  }

  return el;
}
