import $ from 'jquery';

export default function followingEyesAnimation () {
  var $body = $('body'),
    $leftEye = $('#left-eye'),
    $rightEye = $('#right-eye'),
    $leftEyePosHelper = $('#left-eye-position-helper'),
    $rightEyePosHelper = $('#right-eye-position-helper'),
    HALF_DIVISOR = 2,
    MIN_TRANSLATE_X_DIVISOR_LEFT_EYE = 0.3,
    MIN_TRANSLATE_X_DIVISOR_RIGHT_EYE = 0.3,
    MAX_TRANSLATE_X_DIVISOR_LEFT_EYE = 0.35,
    MAX_TRANSLATE_X_DIVISOR_RIGHT_EYE = 0.2,
    FACTOR_X = 40,
    FACTOR_Y = 32;

  if ($leftEye.length == 0 || $rightEye.length == 0) {
    return;
  }

  var eyes = {
    left: {
      el: $leftEye,
      posX: $leftEyePosHelper.offset().left + $leftEye.width() / HALF_DIVISOR,
      posY: $leftEyePosHelper.offset().top + $leftEye.height() / HALF_DIVISOR,
      minTranslateX: $leftEye[0].getBoundingClientRect().width * MIN_TRANSLATE_X_DIVISOR_LEFT_EYE,
      maxTranslateX: $leftEye[0].getBoundingClientRect().width * MAX_TRANSLATE_X_DIVISOR_LEFT_EYE,
      minTranslateY: $leftEye[0].getBoundingClientRect().height,
      maxTranslateY: $leftEye[0].getBoundingClientRect().height,
    },
    right: {
      el: $rightEye,
      posX: $rightEyePosHelper.offset().left + $rightEye.width() / HALF_DIVISOR,
      posY: $rightEyePosHelper.offset().top + $rightEye.height() / HALF_DIVISOR,
      minTranslateX: $rightEye[0].getBoundingClientRect().width * MIN_TRANSLATE_X_DIVISOR_RIGHT_EYE,
      maxTranslateX: $rightEye[0].getBoundingClientRect().width * MAX_TRANSLATE_X_DIVISOR_RIGHT_EYE,
      minTranslateY: $rightEye[0].getBoundingClientRect().height,
      maxTranslateY: $rightEye[0].getBoundingClientRect().height,
    },
  };

  $body.on('mousemove', function(event) {
    var mouseX = event.pageX,
      mouseY = event.pageY;

    moveEye(mouseX, mouseY, 'left');
    moveEye(mouseX, mouseY, 'right');
  });

  var moveEye = function(mouseX, mouseY, eye) {
    var translateX = (mouseX - eyes[eye].posX) / FACTOR_X,
      translateY = (mouseY - eyes[eye].posY) / FACTOR_Y;

    if (translateX >= eyes[eye].maxTranslateX) {
      translateX = eyes[eye].maxTranslateX;
    } else if (translateX <= -eyes[eye].minTranslateX) {
      translateX = -eyes[eye].minTranslateX;
    }

    if (translateY >= eyes[eye].maxTranslateY) {
      translateY = eyes[eye].maxTranslateY;
    } else if (translateY <= -eyes[eye].minTranslateY) {
      translateY = -eyes[eye].minTranslateY;
    }

    eyes[eye].el.css('transform', 'translateX(' + translateX + 'px) translateY(' + translateY + 'px)')
  }
}
