import $ from 'jquery';
import sendNewsletterRequest from './send-newsletter-request';

const EVENT_NAMESPACE = '.newsletter-signup';
const SUBSCRIBE_MESSAGE = "Way to go, you're subscribed. You'll hear from us soon. Have a great day.";
const RESPONSE_KEYWORD = "confirmed";

export function newsletterSignup() {
  const $form = $('.js-submit-cm');

  $form.parsley();
  $form.on('submit', event => event.preventDefault() );
  $form.on(`submit${EVENT_NAMESPACE}`, () => submitToCampaignMonitor($form) );
}

function submitToCampaignMonitor ($form) {
  const isValid = $form.parsley().isValid();

  if (!isValid) {
    return;
  }

  const $submitButtons = $form.find('button[type=submit], input:submit');
  const $message = $( $form.data('messageSelector') );

  $form
    .off(EVENT_NAMESPACE)
    .addClass('is-loading');
  $submitButtons
    .on('click', event => event.preventDefault() )
    .addClass('is-loading');

  sendNewsletterRequest( $form.attr('action'), $form.serialize() )
    .done( response => {
      $submitButtons
        .add($form)
        .removeClass('is-loading');
      $message
        .add($form)
        .addClass('is-success')

      displayMessage($message, response);
    })
    .fail( response => {
      $submitButtons
        .add($form)
        .removeClass('is-loading');
      $message
        .add($form)
        .addClass('is-error')

      displayMessage($message, response);
    });
}

function displayMessage ($message, response) {
  if ( $.type(response) === 'object' && response.Message ) {
    if (response.Message.indexOf(RESPONSE_KEYWORD) > 0) {
      $message.text(SUBSCRIBE_MESSAGE);
    } else {
      $message.text(response.Message);
    }
  }
}
