import $ from 'jquery';

export const DRAG_OVER_CLASS = 'is-drag-over';
export const MAX_FILE_SIZE_IN_MB = 100;
export const EVENT_NAMESPACE = '.upload-box';
export const EXPOSE_DATA_EVENT = 'upload-box:file-update';

export default function uploadBox () {
  const $boxes = $('.c-upload-box');
  $boxes.each(initUploadBox);
}

export function initUploadBox (index, box) {
  const $box = $(box);
  const els = {
    $box,
    $fileInput: $box.find('input[type="file"]'),
    $infoText: $box.find('.js-text'),
    $errorText: $box.find('.js-error'),
    $defaultText: $box.find('.js-default-text'),
    $tryAgain: $box.find('.js-try-again'),
  };
  const errorProps = {
    limitInMegabytes: parseInt(els.$box.data('limitInMegabytes'), 10) || MAX_FILE_SIZE_IN_MB,
    allowedExtensions: getAllowedExtensionsArray(els.$box.data('allowedExtensions') ),
    fileSizeErrorMessage: els.$box.data('fileSizeError'),
    fileTypeErrorMessage: els.$box.data('fileTypeError'),
    fileNumberError: els.$box.data('fileNumberError')
  };

  resetFileInput({ els, errorProps });
  els.$box.on('drag dragstart dragend dragover dragenter dragleave drop', cancelDefaultActions);
  els.$box.on('dragover dragenter', () => handleDragOver(els.$box));
  els.$box.on('dragleave dragend drop', () => handleDragLeave(els.$box));
  $box.on('drop', handleDrop.bind(null, els, errorProps));
  els.$tryAgain.on('click', handleTryAgainClick.bind(null, els));
}

export function getAllowedExtensionsArray (extensions) {
  if (!extensions) {
    return [];
  }

  return extensions.split(',').map( extension => extension.trim() );
}

export function exposeData (els, droppedData) {
  const fileName = els.$fileInput.attr('name');

  if (droppedData) {
    els.$box.trigger(EXPOSE_DATA_EVENT, [{
      name: fileName,
      data: droppedData
    }]);
  }
}

export function handleFileDrop ({ els, errorProps, files, callback }) {
  const ALLOWED_FILES_NUMBER = 1;

  if (!files.length) {
    return;
  }

  if ( !validateFilesNumber(files, ALLOWED_FILES_NUMBER) ) {
    showError({
      els,
      error: errorProps.fileNumberError
    });

    return;
  }

  const file = files[0];
  handleFile({ file, els, errorProps, callback });
}

function validateFilesNumber (files, allowedNumber) {
  return files.length <= allowedNumber;
}

export function handleFileChange ({ els, errorProps, callback }) {
  const file = els.$fileInput[0].files[0];
  handleFile({ file, els, errorProps, callback });
  resetFileInput({ els, errorProps });
}

export function handleFile ({ file, els, errorProps, callback }) {
  if (!file) {
    return;
  }

  const {
    limitInMegabytes,
    allowedExtensions,
    fileTypeErrorMessage
  } = errorProps;

  if ( !validateFileSize(file, limitInMegabytes) ) {
    showError({
      els,
      error: errorProps
        .fileSizeErrorMessage
        .replace('%', `${limitInMegabytes} MB`)
    });

    return;
  }

  if ( !validateExtension(file, allowedExtensions) ) {
    showError({
      els,
      error: fileTypeErrorMessage
    });

    return;
  }

  updateInfoText({
    els,
    name: file.name
  });

  if (callback) {
    callback(file);
  }
}

function validateFileSize (file, limitInMegabytes) {
  const DIVIDER = 1024;
  return file.size / DIVIDER / DIVIDER <= limitInMegabytes;
}

function validateExtension (file, allowedExtensions) {
  const fileExtension = file.name.split('.').reverse()[0].trim();
  return allowedExtensions.some( extension => extension === fileExtension);
}

function showError ({ error, els }) {
  els.$errorText.text(error);
  els.$errorText.css('display', 'inline');
  els.$tryAgain.css('display', 'inline-block');
  els.$infoText.css('display', 'none');
  els.$defaultText.css('display', 'none');
}

function updateInfoText ({ name, els }) {
  els.$infoText.text(name);
  els.$infoText.css('display', 'inline');
  els.$tryAgain.css('display', 'none');
  els.$errorText.css('display', 'none');
  els.$defaultText.css('display', 'none');
}

function resetText (els) {
  els.$infoText.css('display', 'none');
  els.$errorText.css('display', 'none');
  els.$tryAgain.css('display', 'none');
  els.$defaultText.css('display', 'block');
}

function handleDrop (els, errorProps, event) {
  const { files } = event.originalEvent.dataTransfer;

  resetFileInput({ els, errorProps });

  handleFileDrop({
    els, errorProps, files,
    callback: exposeData.bind(null, els)
  });
}

function cancelDefaultActions (event) {
  event.preventDefault();
  event.stopPropagation();
}

export function resetFileInput ({ els, errorProps }) {
  const $newFileInput = $(`
    <input
      type="file"
      name="${els.$fileInput.attr('name')}"
      id="${els.$fileInput.attr('id')}"
      class="${els.$fileInput.attr('class')}"
    >
  `);

  els.$fileInput.replaceWith($newFileInput);
  els.$fileInput = $newFileInput;

  els.$fileInput
    .off(EVENT_NAMESPACE)
    .on(`change${EVENT_NAMESPACE}`, () => {
      handleFileChange({
        els, errorProps,
        callback: exposeData.bind(null, els)
      });
    });
}

export function handleTryAgainClick (els, event) {
  event.preventDefault();
  resetText(els);
}

export function handleDragOver ($box) {
  $box.addClass(DRAG_OVER_CLASS);
}

export function handleDragLeave ($box) {
  $box.removeClass(DRAG_OVER_CLASS);
}
