import $ from 'jquery';
import Mustache from 'mustache';
import Waypoint from 'waypoints';
import imagesLoaded from 'imagesloaded';

const EVENT_NAMESPACE = '.load-more-blog-posts';
export const DEFAULT_NEXT_PAGE = 2;
export const LOADING_CLASS = 'is-loading';

export function loadMoreBlogPosts(triggerElementIndexes) {
  const FIRST_TRIGGER_EL_INDEX = 2;
  const SECOND_TRIGGER_EL_INDEX = 8;
  const $loadMore = $('a.js-load-more-blog-posts');

  if (!$loadMore.length) {
    return;
  }

  if (!triggerElementIndexes) {
    triggerElementIndexes = [FIRST_TRIGGER_EL_INDEX, SECOND_TRIGGER_EL_INDEX];
  }

  const containerSelector = $loadMore.data('blogPostsContainerSelector')
  const templateSelector = $loadMore.data('blogPostsTemplateSelector')
  const $container = $(containerSelector);
  const counter = 0;
  const autoLoadNr = triggerElementIndexes.length;
  const initialTriggerIndex = triggerElementIndexes.shift();

  const renderProps = {
    counter,
    nextPage: $loadMore.data('nextPage') || DEFAULT_NEXT_PAGE,
    template: $(templateSelector).html(),
    url: $loadMore.attr('href'),
    triggerEl: getTriggerEl($container, initialTriggerIndex),
    triggerElementIndexes,
    autoLoadNr,
    $container
  };

  $loadMore.on('click', event => event.preventDefault() );
  applyEvents($loadMore, renderProps);
}

function applyEvents ($loadMore, renderProps) {
  const { counter, autoLoadNr } = renderProps;

  if (counter < autoLoadNr) {
    applyScrollListener($loadMore, renderProps);
  } else {
    $loadMore
      .off(EVENT_NAMESPACE)
      .one(`click${EVENT_NAMESPACE}`, () => fetchData($loadMore, renderProps) );
  }
}

function applyScrollListener ($loadMore, renderProps) {
  const { $container, triggerEl } = renderProps;

  const waypoint = new Waypoint({
    element: triggerEl,
    offset: 'bottom-in-view',
    handler (direction) {
      if (direction === 'down') {
        fetchData($loadMore, renderProps);
        waypoint.context.destroy();
      }
    }
  });

  imagesLoaded($container[0], () => {
    waypoint.context.refresh();
  });
}

function fetchData ($loadMore, renderProps) {
  const INCREMENT = 1;
  const { url, nextPage, template, $container, counter, triggerElementIndexes } = renderProps;
  const newTriggerIndex = triggerElementIndexes.shift();

  $loadMore
    .add($container)
    .addClass(LOADING_CLASS);

  $.get( getUrl(url, nextPage) )
    .done(response => {
      const { data, meta } = response;
      const { pagination } = meta;
      const markup = getMarkup(template, data);

      if (pagination.current_page < pagination.total_pages) {
        renderPosts($container, markup);

        applyEvents($loadMore, {
          ...renderProps,
          nextPage: nextPage + INCREMENT,
          counter: counter + INCREMENT,
          triggerElementIndexes,
          triggerEl: getTriggerEl($container, newTriggerIndex)
        });
      } else {
        $loadMore.fadeOut();
      }

      $loadMore
        .add($container)
        .removeClass(LOADING_CLASS);
    })
    .fail( (response, status, error) => {
      console.log(`something went wrong: ${error}`);
    });
}

function getTriggerEl ($container, index) {
  if (index !== undefined) {
    return $container.children().eq(index)[0]
  }
}

function renderPosts ($container, markup) {
  $container.append(markup);

  const $posts = $container.children();

  $posts
    .addClass('o-col-medium-8')
}

function getMarkup (template, data) {
  return data.map( post => {
    return Mustache.render(template, post);
  }).join('');
}

function getUrl(url, nextPage) {
  return `${url}?page=${nextPage}`;
}
