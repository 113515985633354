import $ from 'jquery';
import escapeStringRegexp from 'escape-string-regexp';

export default function syncWithInputs () {
  const $targets = $('input[data-sync-with-inputs]');

  $targets.each( (index, target) => applyEvents( $(target) ) );
}

function applyEvents ($target) {
  const idSelectors = $target
    .data('syncWithInputs')
    .split(',')
    .map( selector => selector.trim() );
  const template = $target.data('syncWithInputsTemplate');
  const values = [];

  idSelectors.forEach( (selector, index) => {
    const $input = $(selector);

    values.push( $input.val() );

    $input.on('input', () => {
      values[index] = $input.val();
      setValue($target, values, idSelectors, template);
    });
  });
}

function setValue ($target, values, idSelectors, template = idSelectors.join('')) {
  const connectedVals = values.reduce( (prevTemplate, value, index) => {
    const escapedSelector = escapeStringRegexp(idSelectors[index]);
    const regExp = new RegExp(escapedSelector, 'g');

    return prevTemplate.replace(regExp, value);
  }, template);

  $target.val(connectedVals);
}
