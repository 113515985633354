export const WINDOW_WIDTH = 550;
export const WINDOW_HEIGHT = 520;
export const HALF_DIVISOR = 2;

export function createSharerWindow() {
  let links = document.querySelectorAll('.js-sharer-window');

  for (let i = 0; i < links.length; i++) {
    let link = links[i];

    link.addEventListener('click', function(event) {
      event.preventDefault();
      newCenteredWindow(link.href, link.title, window);
    });
  }

  function newCenteredWindow(url, title, win, width = WINDOW_WIDTH, height = WINDOW_HEIGHT) {
    let y = win.top.outerHeight / HALF_DIVISOR + win.top.screenY - ( height / HALF_DIVISOR);
    let x = win.top.outerWidth / HALF_DIVISOR + win.top.screenX - ( width / HALF_DIVISOR);

    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + width + ', height=' + height + ', top=' + y + ', left=' + x);
  }
}
