import {
  logoTranslateFrom,
  switchToSticky,
  stickedHeaderHeight,
  headerHeight,
  menuTriggerTranslateFrom,
  headerNavTranslateFrom,
} from "./responsive-variables";

export const MENU_MAX_TRANSLATE = 11.8;
export const STICKY_CLASS = 'is-sticked';
export const NEAR_TOP_CLASS = 'is-near-top';
export const CIRCLE_REVEAL_START_POSITION = 1000;
export const CIRCLE_REVEAL_END_POSITION = 0;
export const CIRCLE_REVEAL_ACTUAL = 995;
export const DECREMENTER = 50;
export const RECTANGLE_MASK_START = 0;
export const RECTANGLE_MASK_END = 223;

let svgMaskStrokeDashOffset = CIRCLE_REVEAL_ACTUAL;

export function initStickyWebsiteHeader () {
  const els = {
    svgMaskCircle: document.querySelector('#Ellipse'),
    svgMaskRectangle: document.querySelector('#LineMask rect'),
    headerLogo: document.querySelector('.c-website-header__logo'),
    header: document.querySelector('.c-website-header'),
    menuTrigger: document.querySelector('.c-header-menu'),
    headerNav: document.querySelector('.c-header-nav'),
    menuLayerLogo: document.querySelector('.c-menu-layer .c-website-header__logo')
  };

  if (isIOS()) {
    iOSstickyFix(els.header);
  }

  startMainLoop({ els });
}

export function startMainLoop ({
  els,
  isHeaderSticked = false,
  menuTop = 0,
  previousScroll = 0
} = {}) {
  const props = arguments[0];
  const currentScroll = window.pageYOffset;
  const directionDelta = currentScroll - previousScroll;
  const newMenuTop = getUpdatedMenuTop(menuTop, directionDelta, stickedHeaderHeight);

  if (currentScroll === previousScroll || document.body.classList.contains('u-fixed')) {
    requestAnimationFrame( startMainLoop.bind(null, props) );
  } else {
    if ( checkIfHeaderShouldBeSticked({ isHeaderSticked, currentScroll, switchToSticky, directionDelta }) ) {
      isHeaderSticked = toggleHeaderStickyPosition(els, isHeaderSticked);
    }

    if ( checkIfHeaderShouldBeAnimated(isHeaderSticked, currentScroll, headerHeight) ) {
      updateHeaderElementsStyles({
        els, logoTranslateFrom, menuTriggerTranslateFrom, headerNavTranslateFrom,
        currentScroll, headerHeight
      });
    } else {
      clearHeaderElementsStyles(els);
    }

    if (isHeaderSticked) {
      if ( checkIfHeaderShouldBeAnimated(isHeaderSticked, currentScroll, headerHeight)) {
        const logoStrokeDashOffset =  CIRCLE_REVEAL_START_POSITION * (currentScroll / headerHeight);
        const rectangleMaskWidth =  RECTANGLE_MASK_END * (1 - currentScroll / headerHeight);

        updateDashOffset(els.svgMaskCircle, logoStrokeDashOffset);
        updateLineMaskWidth(els.svgMaskRectangle, rectangleMaskWidth);
      } else {
        updateDashOffset(els.svgMaskCircle, CIRCLE_REVEAL_ACTUAL);
        updateLineMaskWidth(els.svgMaskRectangle, RECTANGLE_MASK_START);
      }
    } else {
      updateDashOffset(els.svgMaskCircle);
      updateLineMaskWidth(els.svgMaskRectangle, RECTANGLE_MASK_END);
    }

    if (isHeaderSticked) {
      updateElYPosition(els.header, newMenuTop);
    }

    requestAnimationFrame(startMainLoop.bind(null, {
      isHeaderSticked, els,
      menuTop: newMenuTop,
      previousScroll: currentScroll
    }));
  }
}

export function checkIfHeaderShouldBeSticked ({
  isHeaderSticked, currentScroll, switchToSticky, directionDelta
} = {}) {
  const isStickedAndAtTheTop = isHeaderSticked && currentScroll <= 0;
  const isStickingAllowed = currentScroll >= switchToSticky;
  const isScrollTop = directionDelta < 0;
  const isStick = !isHeaderSticked && isStickingAllowed && isScrollTop;

  return isStickedAndAtTheTop || isStick;
}

export function checkIfHeaderShouldBeAnimated (isHeaderSticked, currentScroll, headerHeight) {
  return isHeaderSticked && currentScroll < headerHeight;
}

export function updateHeaderElementsStyles ({
  els, logoTranslateFrom, menuTriggerTranslateFrom, headerNavTranslateFrom,
  currentScroll, headerHeight,
  nearTopClass = NEAR_TOP_CLASS
}= {}) {
  const headerLogoTransform = logoTranslateFrom * (currentScroll / headerHeight);
  const menuTriggerTransform = menuTriggerTranslateFrom * (currentScroll / headerHeight);
  const headerNavTransform = headerNavTranslateFrom * (currentScroll / headerHeight);

  updateElYPosition(els.headerLogo, headerLogoTransform);
  updateElYPosition(els.menuTrigger, menuTriggerTransform);
  updateElYPosition(els.headerNav, headerNavTransform);
  els.header.classList.add(nearTopClass);
}

export function clearHeaderElementsStyles (els, nearTopClass = NEAR_TOP_CLASS) {
  if (els.headerLogo.style.transform) {
    updateElYPosition([els.headerLogo, els.menuTrigger, els.headerNav]);
    els.header.classList.remove(nearTopClass);
  }
}

export function getUpdatedMenuTop (menuTop, directionDelta, stickedHeaderHeight) {
  const newMenuTop = menuTop - directionDelta;
  return Math.min(Math.max(newMenuTop, -stickedHeaderHeight), 0);
}

export function updateLineMaskWidth (el, position, unit = 'px') {
  const transform = `${position}${unit}`;

  el.style.width = transform;
}

export function updateDashOffset (el, position, unit = 'px') {
  const transform = position !== undefined ? `${position}${unit}` : `${CIRCLE_REVEAL_END_POSITION}${unit}`;

  el.style.strokeDashoffset = transform;
}

export function updateElYPosition (els, position, unit = 'px') {
  const transform = position !== undefined ? `translateY(${position}${unit}) translateZ(0px)` : '';

  if (els.length) {
    for (let i = 0; i < els.length; i++) {
      els[i].style.transform = transform;
    }
  } else {
    els.style.transform = transform;
  }
}

export function toggleHeaderStickyPosition(els, isHeaderSticked, stickyClass = STICKY_CLASS) {
  const updatedIsHeaderSticked = !isHeaderSticked;

  if (updatedIsHeaderSticked) {
    addStickyClasses(els, stickyClass);
  } else {
    removeStickyClasses(els, stickyClass);
  }

  return updatedIsHeaderSticked;
}

function addStickyClasses (els, stickyClass) {
  els.header.classList.add(stickyClass);
  els.headerLogo.classList.add(stickyClass);
  els.menuTrigger.classList.add(stickyClass);
  els.headerNav.classList.add(stickyClass);
  els.menuLayerLogo.classList.add(stickyClass);
}

function removeStickyClasses (els, stickyClass) {
  els.header.classList.remove(stickyClass);
  els.headerLogo.classList.remove(stickyClass);
  els.menuTrigger.classList.remove(stickyClass);
  els.headerNav.classList.remove(stickyClass);
  els.menuLayerLogo.classList.remove(stickyClass);
}

function isIOS() {
  var iDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ];
  while (iDevices.length) {
    if (navigator.platform === iDevices.pop()) {
      return true;
    }
  }
  return false;
}

function iOSstickyFix (header) {
  // Fix problem on iOS with menu not appearing when scrolling for the first time
  // unfortunatly sometimes logo/menu stays displayed after scrolled and refresh

  // To counter this incorrect displaying behaviour, add an initial class with css position:absolute
  // Remove this class on first scroll as seen below.

  requestAnimationFrame(() => {
    header.classList.add('is-fixed', 'initial-scroll');
    requestAnimationFrame(() => header.classList.remove('is-fixed', 'initial-scroll'))
  });
}
