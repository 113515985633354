export function createWorkableApplyNowButtons() {
  window.addEventListener('load', () => {
    const workableJobsEls = document.querySelectorAll('.whr-item');

    for (let i = 0; i < workableJobsEls.length; i++) {
      const jobElement = workableJobsEls[i];
      const titleElement = jobElement.querySelector('.whr-title');
      const jobLinkElement = jobElement.querySelector('a');

      if (!titleElement || !jobLinkElement) {
        return;
      }

      jobLinkElement.setAttribute('target', '_blank');

      const applyURL = jobLinkElement.href;

      titleElement.insertAdjacentHTML('afterend', `
        <a class="c-button" target="_blank" href="${applyURL}">Apply Now</a>
      `);
    }
  });
}
