/*
  Project: The Village of Useful
  Author: Xfive
 */

import 'parsleyjs';
import { initMenuModal } from './menu-modal';
import { initStickyWebsiteHeader } from './sticky-website-header';
import uploadBox from './upload-box';
import { createWorkableApplyNowButtons } from './workable-apply-button';
import { newsletterSignup } from './newsletter-signup';
import { loadMoreBlogPosts } from './load-more-blog-posts';
import { careersSubmit } from './careers';
import { createSharerWindow } from './create-sharer-window';
import { dockSocialWidget } from './social-widget-docking.js';
import syncWithInputs from './sync-with-inputs';
import followingEyesAnimation from './following-eyes-animation';
import { copyToClipboard } from './copy-to-clipboard.js';
import { bannerImage } from './banner-image.js';
import { classToggler } from './class-toggler.js';
import { scrollingLinks } from './scrolling-link.js';

initMenuModal();
initStickyWebsiteHeader();
uploadBox();
createWorkableApplyNowButtons();
newsletterSignup();
loadMoreBlogPosts();
careersSubmit();
createSharerWindow();
dockSocialWidget();
syncWithInputs();
followingEyesAnimation();
copyToClipboard();
bannerImage();
classToggler();
scrollingLinks();

window.recaptchaCallback = function() {
  $('#btnSubmit').removeAttr('disabled');
};

