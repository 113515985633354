import $ from 'jquery';

window.$ = $;

const RESIZE_DELAY = 300;
const STICKED_CLASS = 'is-sticked';
const SWITCH_TO_STICKY_OFFSET = 100;
const $websiteHeader = $('.c-website-header');
const $websiteHeaderLogo = $('.c-website-header__logo');
const $headerNav = $('.c-header-nav');
const $menuTrigger = $('.c-header-menu');
const $spaceForHeader = $('.o-space-for-header');
let resizeTimer;

export let headerHeight;
export let switchToSticky;
export let stickedHeaderHeight;
export let logoTranslateFrom;
export let menuTriggerTranslateFrom;
export let headerNavTranslateFrom;

setVars();
setVarsWithStickyClass();

$(window).on('resize', () => {
  clearTimeout(resizeTimer);

  resizeTimer = setTimeout( () => {
    setVars();
    setVarsWithStickyClass();
  }, RESIZE_DELAY);
});

function setVars () {
  headerHeight = parseInt( $websiteHeader.outerHeight(), 10 );
  switchToSticky = parseInt( $spaceForHeader.css('marginTop'), 10 ) + SWITCH_TO_STICKY_OFFSET;
}

function setVarsWithStickyClass () {
  const elsArr = setStickyClasses($websiteHeader, $websiteHeaderLogo, $menuTrigger, $headerNav);

  stickedHeaderHeight = parseInt( $websiteHeader.outerHeight(), 10 );
  logoTranslateFrom = getTranslateY( $websiteHeaderLogo);
  menuTriggerTranslateFrom = getTranslateY( $menuTrigger);
  headerNavTranslateFrom = getTranslateY( $headerNav);

  setToPreviousState(elsArr);
}

function setStickyClasses (...$els) {
  return $els.map( $el => {
    if ( $el.hasClass(STICKED_CLASS) ) {
      return {
        $el,
        wasSticked: true
      };
    }

    $el.addClass(STICKED_CLASS);

    return {
      $el,
      wasSticked: false
    }
  });
}

function setToPreviousState (elsArr) {
  elsArr.forEach( elItem => {
    const { $el, wasSticked } = elItem;

    if (!wasSticked) {
      $el.removeClass(STICKED_CLASS);
    }
  });
}

function getTranslateY ($el) {
  const cssData = $el.css(['transform', 'webkitTransform']) || {};
  const matrix = cssData.transform || cssData.webkitTransform || '0';
  const matrixArr = matrix
    .replace('matrix(', '')
    .replace(')', '')
    .split(',');

  return parseInt( matrixArr.pop(), 10 );
}
