import $ from 'jquery';

const TIMEOUT = 5000; // milliseconds

export default function (url, data) {
  return $.ajax({
    url,
    data,
    dataType: 'jsonp',
    jsonpCallback: 'showResult',
    timeout: TIMEOUT
  });
}
