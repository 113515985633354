import $ from 'jquery';
import Velocity from 'velocity-animate';

export const DEFAULT_DURATION = 500;
export const DEFAULT_DELAY = 100;
export const MAX_OPACITY = 1;
export const FIXED_CLASS = 'u-fixed';
export const HAS_SCROLL_CLASS = 'has-scroll';

export function initMenuModal() {
  const els = {
    header: document.querySelector('.c-website-header'),
    menuButtons: document.querySelectorAll('.c-menu-trigger'),
    menuLayer: document.querySelector('.c-menu-layer'),
    menuLayerLogo: document.querySelector('.c-menu-layer .c-website-header__logo'),
    menuLayerClose: document.querySelector('.c-menu-layer .c-menu-trigger'),
    menuLayerMainNav: document.querySelector('.c-menu-layer .c-main-nav'),
    menuLayerLogoLink: document.querySelector('.c-menu-layer .c-tvou-logo__link')
  };
  const backgroundColor = getBackgroundColor(els.menuLayer);

  setClickEvents(els.menuButtons, showMenu, [els, backgroundColor]);

  document.addEventListener('focus', function(event) {
    if (els.menuLayer.classList.contains('is-shown') && !els.menuLayer.contains(event.target)) {
      event.stopPropagation();
      els.menuLayerLogoLink.focus();
    }
  }, true);
}

function showMenu (els, backgroundColor, {
  duration = DEFAULT_DURATION, defaultDelay = DEFAULT_DELAY
} = {}) {
  prepareToShowMenu(els);
  removeClickEvents(els.menuButtons);
  window.removeEventListener('keydown', handleEsc, false);
  $(els.menuButtons).addClass('is-open');

  const opacityDeferred = $.Deferred();
  const backgroundDeferred = $.Deferred();

  Velocity([els.menuLayerClose, els.menuLayerMainNav, els.menuLayerLogo], {
    opacity: [MAX_OPACITY, 0]
  }, {
    duration,
    complete () {
      opacityDeferred.resolve();
    }
  });

  Velocity(els.menuLayer, {
    backgroundColor,
    backgroundColorAlpha: [MAX_OPACITY, 0]
  }, {
    duration,
    complete () {
      backgroundDeferred.resolve();
    }
  });

  setTimeout( () => {
    $.when(opacityDeferred, backgroundDeferred)
      .done( () => menuShowCallback(els, window.pageYOffset, backgroundColor) );
  }, defaultDelay);
}

function menuShowCallback (els, currentScroll, backgroundColor) {
  finishShowingMenu(els);
  setClickEvents(els.menuButtons, hideMenu, [{ els, currentScroll, backgroundColor }]);
  handleEsc.callback = hideMenu.bind(null, { els, currentScroll, backgroundColor });
  window.addEventListener('keydown', handleEsc, false);
}

function hideMenu ({
  els, currentScroll, backgroundColor,
  fixedClass = FIXED_CLASS,
  duration = DEFAULT_DURATION,
  defaultDelay = DEFAULT_DELAY,
  scrollClass = HAS_SCROLL_CLASS
}) {
  document.body.classList.remove(fixedClass);
  els.menuLayer.classList.remove(scrollClass);
  window.scrollTo(0, currentScroll);
  removeClickEvents(els.menuButtons);
  window.removeEventListener('keydown', handleEsc, false);
  $(els.menuButtons).removeClass('is-open');

  const opacityDeferred = $.Deferred();
  const backgroundDeferred = $.Deferred();

  Velocity([els.menuLayerClose, els.menuLayerMainNav, els.menuLayerLogo], {
    opacity: 0
  }, {
    duration,
    complete () {
      opacityDeferred.resolve();
    }
  });

  Velocity(els.menuLayer, {
    backgroundColor,
    backgroundColorAlpha: 0
  }, {
    duration,
    complete () {
      backgroundDeferred.resolve();
    }
  });

  setTimeout( () => {
    $.when(opacityDeferred, backgroundDeferred)
      .done( () => menuHideCallback(els, backgroundColor) );
  }, defaultDelay);
}

function menuHideCallback (els, backgroundColor) {
  finishHidingMenu(els);
  setClickEvents(els.menuButtons, showMenu, [els, backgroundColor]);
}

export function prepareToShowMenu (els, defaultLogoYTransform = 'translateY(0px)') {
  els.menuLayer.style.left = 0;
  els.menuLayer.style.top = 0;
  els.menuLayerLogo.style.transform = defaultLogoYTransform;
}

export function finishShowingMenu (els, {
  fixedClass = FIXED_CLASS,
  scrollClass = HAS_SCROLL_CLASS
} = {}) {
  document.body.classList.add(fixedClass);
  els.menuLayer.classList.add(scrollClass);
}

export function finishHidingMenu (els, {
  hideTopPosition = '-9999em',
  hideLeftPosition = '-9999em'
} = {}) {
  els.menuLayer.style.top = hideTopPosition;
  els.menuLayer.style.left = hideLeftPosition;
}

export function transformRgbToHex (rgbColor) {
  const DECIMAL = 10;
  const HEX = 16;
  const ALLOWED_CHARACTERS = 2;
  const colors = rgbColor
    .replace('rgb(', '')
    .replace(')', '')
    .split(',')
    .map( nr => parseInt(nr, DECIMAL) );

  if ( colors.some( color => isNaN(color) ) ) {
    return rgbColor;
  }

  return '#' + colors
    .map( color => `0${color.toString(HEX)}`.slice(-ALLOWED_CHARACTERS) )
    .join('');
}

export function setClickEvents(targets, callback, props) {
  handleClick.callback = callback.bind(null, ...props);

  for (let i = 0; i < targets.length; i++) {
    targets[i].addEventListener('click', handleClick, false)
  }
}

export function removeClickEvents(targets) {
  for (let i = 0; i < targets.length; i++) {
    targets[i].removeEventListener('click', handleClick, false);
  }
}

function handleClick (event) {
  event.preventDefault();
  if (handleClick.callback) {
    handleClick.callback();
  }
}

function handleEsc (event) {
  const ESC_CODE = 27;

  if (event.keyCode === ESC_CODE) {
    if (handleEsc.callback) {
      handleEsc.callback();
    }
  }
}

export function getBackgroundColor (el) {
  const backgroundRgbColor = getComputedStyle(el, null)['background-color'];
  const backgroundHexColor = transformRgbToHex(backgroundRgbColor);

  return backgroundHexColor;
}
