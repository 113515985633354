import smoothscroll from 'smoothscroll-polyfill';

export function scrollingLinks () {
  let selector = document.querySelectorAll('.js-scrolling-link');

  if (selector != null) {
    smoothscroll.polyfill();

    for (var i = selector.length - 1; i >= 0; i--) {
      selector[i].addEventListener('click', clickEvent);
    }
  }
}

function clickEvent(event) {
  event.preventDefault();

  const targetSelector = event.target.hash;
  const targetElement = document.querySelector(targetSelector);
  const options = {
    top: targetElement.offsetTop,
    left: 0,
    behavior: 'smooth',
  }

  window.scrollTo(options);
}
